import { defineType } from "sanity";

export default defineType({
    name: "siteSettings",
    title: 'Site Settings',
    type: 'document',
    fields: [
        {
            name: 'title',
            title: 'Site Title',
            type: 'string',
            initialValue: 'The Outcome Network Inc.'
        },
        {
            name: 'description',
            title: 'Site Description',
            type: 'text',
        },
        {
            name: 'adminEmail',
            title: 'Administrator Email Address',
            type: 'email',
        },
    ],
})