import React, { useEffect, useState } from "react";
import { ImFacebook, ImLinkedin2, ImTwitter } from "react-icons/im";
import { playIcon } from "../../assets";
import {
  aboutImage,
  missionIcon,
  uniquenessImage,
  uniquenessImage2,
} from "../../assets/images/about";
import { GetStartedBanner, Header } from "../../components";
import { aboutContent } from "../../constants/data";
import "./about.css";
import { useRefLinks } from "../../hooks/useRefLinks";
import { useScrollTo } from "../../hooks/scrollToContext";
import { useNavigate } from "react-router-dom";
import ModalVideo from "react-modal-video";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import { motion } from "framer-motion";
import { fadeInAnimation, verticalAnimation } from "../../utils/Animations";
import Img from "../../components/Img";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "../../utils/urlFor";

const About = () => {
  const [mission, uniqueness, culture] = useRefLinks();
  const { scrollToSection, currentSection, setCurrentSection } = useScrollTo();

  const [isOpen, setOpen] = useState(false);
  const [aboutData, setAboutData] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [missionRef, controls1] = useScrollAnimation(0.1);
  const [uniquenessRef, controls2] = useScrollAnimation();
  const [cultureRef, controls3] = useScrollAnimation();
  const [empowerRef, controls4] = useScrollAnimation();
  const [teamRef, controls5] = useScrollAnimation();

  const descriptionExists = () =>
    aboutData?.hasOwnProperty("headerDescription");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]{..., missionImage->, uniqueImages[]->, empowermentImage->, teamMembers[] {..., image->}}`
      )
      .then((data) => {
        setAboutData(data[0]);
        setLoading(false);
      })
      .catch(console.error);

    sanityClient
      .fetch("*[_type == 'gallery']")
      .then((data) => {
        console.log("gallery data: ", data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("about data: ", aboutData);
  }, [aboutData]);

  useEffect(() => {
    switch (currentSection) {
      case "mission":
        scrollToSection(mission);
        break;
      case "uniqueness":
        scrollToSection(uniqueness);
        break;
      case "culture":
        scrollToSection(culture);
        break;
      default:
        break;
    }
  }, [currentSection]);

  return (
    <div id="about">
      <Header title={aboutData?.headerTitle}>
        {descriptionExists() ? (
          <p className="lead">{aboutData?.headerDescription || "Loading..."}</p>
        ) : (
          loading && <p>Loading...</p>
        )}
      </Header>

      {/* Mission section */}
      <section ref={mission} className="container my-5">
        <div className="row">
          <motion.div
            ref={missionRef}
            variants={fadeInAnimation}
            animate={controls1}
            transition={{ type: "tween", duration: 0.4 }}
            className="col-md-6 col-12"
          >
            <Img
              src={urlFor(aboutData?.missionImage?.image)}
              hash={"LDI|]*-q00%fHXV=?HRm~V%2={9t"}
              alt={aboutData?.missionImage?.image.alt}
              height={"100%"}
            />
          </motion.div>
          <motion.div
            ref={missionRef}
            variants={fadeInAnimation}
            animate={controls1}
            transition={{ type: "tween", duration: 0.7 }}
            className="col-md-6 col-12 justify-content-start"
          >
            <h2 className="mb-3 mission-title">{aboutData?.missionTitle}</h2>

            <BlockContent blocks={aboutData?.missionDescription} />

            {/* Mission Solutions */}
            <ul className="list-group list-group-flush">
              {aboutData?.missionList?.map((solution, index) => (
                <li key={"solutions#" + index} className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <img
                        src={missionIcon}
                        className="mission-icon"
                        alt="mission list icon"
                      />
                    </div>
                    <div className="solution-text">{solution}</div>
                  </div>
                </li>
              ))}
            </ul>
          </motion.div>
        </div>
      </section>

      {/* Uniqueness section */}
      <section ref={uniqueness} className="container my-5">
        <div className="row">
          <motion.div
            ref={uniquenessRef}
            variants={verticalAnimation}
            animate={controls2}
            transition={{ type: "tween", duration: 0.4 }}
            className="col-md-6 col-12"
          >
            <h2 className="mb-3 mission-title">{aboutData?.uniqueTitle}</h2>

            <BlockContent blocks={aboutData?.uniqueDescription} />
          </motion.div>
          <motion.div
            ref={uniquenessRef}
            initial="hidden"
            variants={verticalAnimation}
            animate={controls2}
            transition={{ type: "tween", duration: 0.7 }}
            className="col-md-6 col-12"
          >
            {aboutData?.uniqueImages?.length > 0 &&
              (aboutData?.uniqueImages.length === 1 ? (
                <div className="">
                  <Img
                    src={urlFor(aboutData?.uniqueImages[0]?.image)}
                    hash={"LNJH%Q17TJ-m~8xUOFs+#N==S%WZ"}
                    classNames={["unique-img2"]}
                    alt={aboutData?.uniqueImages[0]?.image.alt}
                  />
                </div>
              ) : (
                <div className="unique-content">
                  <Img
                    src={urlFor(aboutData?.uniqueImages[0]?.image)}
                    hash={"LNJH%Q17TJ-m~8xUOFs+#N==S%WZ"}
                    classNames={["unique-img"]}
                    alt={aboutData?.uniqueImages[0]?.image.alt}
                  />
                  <Img
                    src={urlFor(aboutData?.uniqueImages[1]?.image)}
                    hash={"LNJH%Q17TJ-m~8xUOFs+#N==S%WZ"}
                    classNames={["unique-img"]}
                    alt="unique image 2"
                  />
                </div>
              ))}
          </motion.div>
        </div>
      </section>

      {/* Values section */}
      <section ref={culture} className="container my-5">
        <div className="row">
          <div
            className="col-12 justify-content-center mx-auto"
            style={{ width: "744px" }}
          >
            <h2 className="mb-3 mission-title text-center">
              {aboutData?.valuesTitle}
            </h2>

            <p className="mb-0 text-center">{aboutData?.valuesDescription}</p>
          </div>

          {/* Values */}
          <div className="col-12 mt-3">
            <div className="row card-group">
              {aboutData?.valuesList?.map((value, index) => (
                <motion.div
                  ref={cultureRef}
                  variants={verticalAnimation}
                  animate={controls3}
                  transition={{
                    type: "tween",
                    duration: index === 0 ? 0.4 : 0.5 + index / 20,
                  }}
                  key={"values#" + index}
                  className="col-md-4 col-12"
                >
                  <div className="card">
                    <div className="value-img-container">
                      <img
                        src={urlFor(value.valueIcon)}
                        className="value-img"
                        alt=""
                      />
                    </div>

                    <div className="card-body">
                      <h4 className="card-title text-center">{value.value}</h4>
                      <p className="card-text text-center">
                        {value.valueDescription}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Empower section */}
      <section id="empower" className="empower-bg">
        <div className="container my-5">
          <div className="row">
            <div className="col-12 justify-content-center mx-auto">
              <div className="d-md-flex d-block align-items-center justify-content-center justify-content-md-between mb-3 py-4 py-md-auto">
                <div className="emp-content mx-auto mx-md-0">
                  <h2 className="mission-title text-center text-md-start">
                    {aboutData?.empowermentTitle}
                  </h2>
                  <p className="text-center text-md-start">
                    {aboutData?.empowermentDescription}
                  </p>
                </div>

                <div className="text-md-end text-center">
                  <button
                    className="btn btn-default resume-btn"
                    onClick={() => {
                      navigate("/submit-resume");
                      setCurrentSection("link-submit-resume-empower");
                    }}
                  >
                    Submit Your Resume
                  </button>
                </div>
              </div>

              <motion.div
                ref={empowerRef}
                variants={verticalAnimation}
                animate={controls4}
                transition={{ type: "tween", duration: 0.5 }}
                className="col-12 mx-auto position-relative d-none d-md-block"
              >
                <Img
                  src={urlFor(aboutData?.empowermentImage?.image)}
                  className={["video-img rounded-4"]}
                  alt={aboutData?.empowermentImage?.image.alt}
                  hash={"LJKA+yX8?b-;kW$%%L8__NDOR5D%"}
                />
                <button
                  onClick={() => setOpen(true)}
                  className="btn btn-default play-btn d-flex align-items-center justify-content-center py-5"
                >
                  <img src={playIcon} className="play-icon me-3" alt="" />
                  <div>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: "20px",
                        color: "#2F2F2F",
                        textTransform: "capitalize",
                      }}
                    >
                      we love what we do
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span
                      className="our-work d-block"
                      style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#58BEE1",
                        textTransform: "lowercase",
                      }}
                    >
                      checkout out our work
                    </span>
                  </div>
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Team section */}
      <section id="team" style={{ backgroundColor: "#F2F2F2" }}>
        <div className="container mt-5">
          <div className="row py-5">
            <div className="col-12" style={{ width: "590px" }}>
              <h2 className="mission-title text-md-start text-center">
                {aboutData?.teamTitle}
              </h2>
              <p className="text-md-start text-center">
                {aboutData?.teamDescription}
              </p>
            </div>

            <div className="col-12">
              <div className="row pb-4">
                {aboutData?.teamMembers?.map((member, index) => (
                  <motion.div
                    ref={teamRef}
                    variants={verticalAnimation}
                    animate={controls5}
                    transition={{
                      type: "tween",
                      duration: index === 0 ? 0.4 : 0.5 + index / 20,
                    }}
                    key={"members#" + index}
                    className="col-lg-4 col-md-6 col-12 mb-4 mb-md-0"
                  >
                    <img
                      src={urlFor(member?.image.image)}
                      className="rounded-4 img-fluid"
                      alt={member?.image.image.alt}
                    />

                    <div className="pt-4 pb-2">
                      <p className="mb-0 text-center member-name">
                        {member.name}
                      </p>
                      <p className="mb-2 text-center member-title">
                        {member.title}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <GetStartedBanner type="service" />

      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={aboutData?.empowermentVideo}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default About;
